<template>
  <div class="examine_order_container">
    <div class="header">
      <div class="info">
        <div>体检套餐：驾驶员体检</div>
        <div>体检人：刘伟杰</div>
        <div>体检时间：2021-04-21</div>
      </div>
      <div class="cost">
        <div>检查费：30.00元</div>
      </div>
    </div>
    <div class="detail">
      <div class="title">收费明细</div>
      <div class="list">
        <van-cell
          v-for="(it,index) in 10"
          :key="index"
          title="2元"
          value="x0.5"
          label="普通视力检查"
        />
      </div>
    </div>
    <van-button
      type="primary"
      block
      class="btn"
      @click="orderBtn"
    >支&nbsp;&nbsp;&nbsp;付</van-button>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Order",
  data() {
    return {};
  },
  created() {},
  methods: {
    orderBtn() {
      this.$router.push({
        path: "/examine/pay"
      });
    }
  }
};
</script>

<style scope>
.examine_order_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}
.examine_order_container .header .info {
  font-size: 40px;
  margin-left: 30px;
  border-bottom: 1px solid skyblue;
  padding: 20px 0;
}
.examine_order_container .header .info div {
  padding: 10px 0;
}
.examine_order_container .header .cost {
  padding-left: 30px;
  font-size: 40px;
  border-bottom: 2px solid skyblue;
}
.examine_order_container .header .cost div {
  margin: 20px 0;
}
.examine_order_container .detail {
  padding-bottom: 100px;
}
.examine_order_container .detail .title {
  padding: 20px 0;
  padding-left: 30px;
  font-size: 40px;
  border-bottom: 1px solid #f5f5f5;
}
.examine_order_container .detail .list {
  padding-bottom: 80px;
}
.examine_order_container .van-cell {
  border-bottom: 1px solid #f5f5f5;
}
.examine_order_container .van-cell__title,
.examine_order_container .van-cell__value,
.examine_order_container .van-cell__label {
  font-size: 40px !important;
}
.examine_order_container .van-cell__label {
  line-height: 70px;
}
.examine_order_container .btn {
  position: fixed;
  left: 0;
  bottom: 60px;
  z-index: 1;
  width: 100%;
  font-size: 40px;
  height: 100px;
}
</style>